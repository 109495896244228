import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h1`
  color: #333;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: ${(props) => (props.success ? '#4caf50' : '#f44336')};
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const Icon = styled.span`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ESuccess = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      setLoading(true);
      setErrorMessage('');

      if (!token) {
        console.log('Missing token');
        setErrorMessage('Missing payment token');
        setLoading(false);
        return;
      }



      try {
        const response = await axios.post(
          'https://web-production-5ee8.up.railway.app/payments/payc/cyberpaytest',
          {
            token
      
          }
        );
        if (response.status === 200) {
          setSuccess(true);
        } else {
          setSuccess(false);
          setErrorMessage('Payment failed');
        }
      } catch (error) {
        console.error('Payment API error:', error);
        setSuccess(false);
        setErrorMessage('An error occurred while processing your payment');
      } finally {
        setLoading(false);
      }
    };

    handlePaymentSuccess();
  }, [token, userInfo]);

  return (
    <Container>
      <Card>
        <Title>Payment Status</Title>
        {loading ? (
          <Loader />
        ) : success ? (
          <>
            <Icon role="img" aria-label="success">✅</Icon>
            <Message success>Success! Your payment was completed.</Message>
          </>
        ) : errorMessage ? (
          <>
            <Icon role="img" aria-label="failure">❌</Icon>
            <Message>{errorMessage}</Message>
          </>
        ) : (
          <>
            <Icon role="img" aria-label="failure">❌</Icon>
            <Message>Failed! There was an issue with your payment.</Message>
          </>
        )}
      </Card>
    </Container>
  );
};

export default ESuccess;
