import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const StudyApp = () => {
  const [isRetrying, setIsRetrying] = useState(false);
  const [queuePosition, setQueuePosition] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    controls.start(i => ({
      scale: [1, 1.2, 1],
      transition: { repeat: Infinity, duration: 2, delay: i * 0.1 }
    }));
    setQueuePosition(Math.floor(Math.random() * (381 - 343 + 1) + 343));
  }, [controls]);

  const handleRetry = () => {
    setIsRetrying(true);
    setTimeout(() => {
      setIsRetrying(false);
      setQueuePosition(Math.floor(Math.random() * (381 - 343 + 1) + 343));
    }, 3000);
  };

  return (
    <div className="h-screen w-screen bg-gray-900 text-white flex flex-col items-center justify-between overflow-hidden relative p-4">
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(100)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-1 h-1 bg-blue-400 rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={controls}
            custom={i}
          />
        ))}
      </div>

      <motion.div
        className="absolute w-1/2 h-1/2 max-w-96 max-h-96 rounded-full bg-blue-500 opacity-10 filter blur-xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.1, 0.2, 0.1],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />

      <div className="flex-1 flex flex-col items-center justify-center relative z-10 text-center">
        <motion.h1 
          className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Servers are full
        </motion.h1>
        <motion.p 
          className="text-lg sm:text-xl md:text-2xl mb-4 text-blue-200"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          Our digital realm is at capacity. Please stand by.
        </motion.p>
        <motion.p
          className="text-md sm:text-lg md:text-xl mb-8 text-blue-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          You are in queue.
        </motion.p>
        <motion.button 
          className={`px-6 py-3 sm:px-8 sm:py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-full font-semibold text-base sm:text-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg ${isRetrying ? 'animate-pulse' : ''}`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRetry}
        >
          {isRetrying ? "Attempting Reconnection..." : "Initiate Retry Sequence"}
        </motion.button>
      </div>

      <motion.div
        className="relative z-10 mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <p className="text-blue-300 mb-2">Server Status</p>
        <div className="flex space-x-2">
          {[...Array(5)].map((_, i) => (
            <motion.div
              key={i}
              className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-red-500"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.5, 1, 0.5],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default StudyApp;
